<template>
  <v-card class="mx-auto" ripple @click="$emit('allergen-edit')">
    <v-card-text class="pb-0">
      <v-list-item three-line class="px-0">
        <v-list-item-avatar>
          <v-img :src="allergen.image_path" class="elevation-2"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ allergen.name | translate($i18n) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span
              v-if="!!$options.filters.translate(allergen.description, $i18n)"
            >
              {{ allergen.description | translate($i18n) }}
            </span>
            <span v-else>
              {{ $t('allergens.allergen.noDescription') }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <v-card-actions>
      <v-chip small class="ma-2 elevation-3 handle" color="white" @click.stop>
        <v-avatar left>
          <v-icon>mdi-sort</v-icon>
        </v-avatar>
        {{ allergen.display_priority }}
      </v-chip>

      <v-spacer></v-spacer>
      <v-btn fab dark small color="red" @click.stop="$emit('allergen-delete')">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    allergen: Object,
  },
};
</script>
