<template>
  <v-main>
    <v-container fluid>
      <v-row align="center">
        <v-col cols="12 d-flex align-baseline justify-space-between">
          <h2 class="display-1 font-weight-light pt-5 pl-5">
            {{ $t('allergens.title') }}
          </h2>
          <v-btn color="primary" v-if="isAdmin" @click="generateAllergens()">
            Generar Alergenos
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <v-col cols="12" v-if="allergens.length == 0">
          <v-alert border="left" colored-border type="info" elevation="2">
            {{ $t('allergens.noAllergens') }}
          </v-alert>
        </v-col>
      </v-row>

      <draggable
        v-model="allergens"
        handle=".handle"
        tag="div"
        class="row align-lg-stretch align-center mb-6"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          v-for="allergen in allergens"
          :key="allergen.id"
        >
          <EasyServeAllergen
            :allergen="allergen"
            @allergen-delete="remove(allergen)"
            @allergen-edit="edit(allergen)"
          ></EasyServeAllergen>
        </v-col>
      </draggable>

      <!-- Help accordion -->
      <!-- <v-col cols="12">
          <eximyr-help></eximyr-help>
        </v-col>-->
      <!-- Help accordion -->

      <!-- Create fab button -->
      <v-fab-transition>
        <v-btn
          color="red"
          fab
          dark
          large
          bottom
          right
          ripple
          fixed
          @click="create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
      <!-- Create fab button -->
    </v-container>

    <!-- Remove buttom sheet -->
    <v-bottom-sheet v-model="removeDialog">
      <v-sheet class="text-center" height="200px" v-if="allergen">
        <div class="pt-6 px-3">
          ¿Estás segur@ que quieres eliminar
          <span class="font-weight-medium"
            >'{{ allergen.name | translate($i18n) }}'</span
          >?
        </div>

        <div
          class="py-3"
          v-if="
            allergen.products_count + allergen.daily_menu_products_count > 0
          "
        >
          <span class="red--text">
            Ten en cuenta que hay
            {{ allergen.products_count + allergen.daily_menu_products_count }}
            producto(s) que dejara(n) de tener este alérgeno asociado.
          </span>
        </div>

        <v-btn class="mt-6 mx-2" @click="removeCanceled">Cancelar</v-btn>
        <v-btn class="mt-6 mx-2" color="error" @click="removeConfirmed">
          Eliminar
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
    <!-- Remove buttom sheet -->

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import EximyrLoading from '../components/EximyrLoading';
import EasyServeAllergen from './components/EasyServeAllergen';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
    EasyServeAllergen,
    draggable,
  },

  data: () => ({
    removeDialog: false,
    allergen: null,
    page: null,
  }),

  computed: {
    ...mapGetters('security', {
      isAdmin: 'isAdmin',
    }),

    ...mapGetters('allergens', {
      loading: 'loading',
    }),

    allergens: {
      get() {
        return this.$store.state.allergens.allergens;
      },

      set(values) {
        this.reorder(values);
      },
    },

    dragOptions() {
      return {
        animation: 200,
        group: 'allergens',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      // Create the payload
      const payload = {
        restaurant: this.$route.params.restaurant,
      };

      this.$store
        .dispatch('allergens/load', payload)
        .then(() => {
          // Clear all errors
          this.clearErrors();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    create() {
      this.$router.push({
        name: 'allergens.create',
      });
    },

    edit(allergen) {
      // Clear all allergens and move to the edit page
      this.$store
        .dispatch('allergens/clear')
        .then(() => {
          this.$router.push({
            name: 'allergens.edit',
            params: { allergen: allergen.id },
          });
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    // Reorder allergens
    reorder(allergens) {
      const payload = {
        restaurant: this.$route.params.restaurant,
        allergens: allergens,
      };

      this.$store
        .dispatch('allergens/reorder', payload)
        .then(() => {
          // Clear all errors
          this.clearErrors();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    remove(allergen) {
      this.allergen = allergen;
      this.removeDialog = true;
    },

    removeCanceled() {
      this.allergen = null;
      this.removeDialog = false;
    },

    removeConfirmed() {
      // Remove this allergen and reload
      this.$store
        .dispatch('allergens/remove', this.allergen)
        .then(() => {
          // Once deleted, reload all allergens in this category
          this.load();
        })
        .catch(error => {
          this.storeError(error);
        });

      this.removeCanceled();
    },

    generateAllergens() {
      // Ask for the permission ...
      if (confirm('¿Seguro generamos los nuevos alergenos?')) {
        this.$store
          .dispatch('allergens/generate', this.$route.params.restaurant)
          .then(() => {
            this.load();
          })
          .catch(error => {
            this.storeError(error);
          });
      }
    },
  },
};
</script>
