<template>
  <v-main>
    <v-container fluid>
      <v-row align="center">
        <eximyr-breadcrumb :breadcrumbs="breadcrumbs"></eximyr-breadcrumb>

        <v-col cols="12">
          <h2 v-if="create" class="display-1 font-weight-light pl-5">
            Crear nuevo alérgeno
          </h2>
          <h2 v-else class="display-1 font-weight-light pl-5">
            Editar alérgeno
          </h2>
        </v-col>

        <!-- Errors and alerts -->
        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row class="flex-row-reverse mb-6">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row class="flex-row-reverse">
                <v-col cols="12" md="5" lg="4">
                  <div class="text-center headline font-weight-thin mt-5 mb-3">
                    Vista previa
                  </div>

                  <v-card class="mx-auto mb-5" max-width="400">
                    <v-card-text class="text-center pb-0">
                      <v-avatar size="100" class="elevation-3">
                        <img :src="computedImage" alt="Alérgeno" />
                      </v-avatar>
                    </v-card-text>
                    <v-card-text class="pb-0">
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ name | translate($i18n) }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ description | translate($i18n) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                  </v-card>

                  <div v-if="isAdmin || isEmployee">
                    <v-btn
                      :disabled="
                        translatedName == name[$i18n.locale] &&
                        translatedDescription == description[$i18n.locale]
                      "
                      block
                      max-width="400"
                      color="primary"
                      @click="googleTranslate"
                    >
                      Traducir Google
                    </v-btn>
                  </div>
                </v-col>
                <!-- End Vista Previa -->

                <v-col cols="12" md="7" lg="8">
                  <v-form ref="form">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-file-input
                          accept="image/png, image/jpg, image/jpeg, image/gif, image/svg"
                          label="Seleccione una imagen"
                          @change="onFilePicked"
                          validate-on-blur
                          :rules="rules.image"
                        ></v-file-input>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-card
                          outlined
                          class="my-2"
                          v-for="l in languages"
                          :key="l.code"
                        >
                          <v-card-text>
                            <v-card-title class="subtitle-1">{{
                              l.language
                            }}</v-card-title>
                            <v-text-field
                              label="Nombre del alérgeno *"
                              :counter="150"
                              prepend-icon="mdi-label-outline"
                              required
                              v-model="name[l.code]"
                              :rules="rules.name"
                            >
                              <template v-slot:append>
                                <img
                                  width="24"
                                  height="24"
                                  :src="l.image_path"
                                  :alt="l.language"
                                />
                              </template>
                            </v-text-field>

                            <v-textarea
                              :counter="255"
                              prepend-icon="mdi-file-document-edit-outline"
                              label="Descripción del alérgeno"
                              v-model="description[l.code]"
                              :rules="rules.description"
                            >
                              <template v-slot:append>
                                <img
                                  width="24"
                                  height="24"
                                  :src="l.image_path"
                                  :alt="l.language"
                                />
                              </template>
                            </v-textarea>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-switch
                          :prepend-icon="active ? 'mdi-eye' : 'mdi-eye-off'"
                          v-model="active"
                          label="Activar el alérgeno"
                        ></v-switch>
                        <small class="text--primary">
                          (*) Indica los campos obligatorios.
                        </small>
                      </v-col>

                      <v-col cols="12" class="text-right">
                        <v-btn class="mr-2" @click="cancel">Cancelar</v-btn>
                        <v-btn color="primary" @click="save">Guardar</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading
      :loading="loading || loadingLanguages || loadingTranslation"
    ></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import goTo from 'vuetify/lib/services/goto';

import { IMAGE_NO_ALLERGEN } from '@/constants';
import { mapGetters } from 'vuex';

import { required } from '@/validators/required';
import { fileRequired } from '@/validators/fileRequired';
import { length } from '@/validators/length';
import { numeric } from '@/validators/numeric';
import { fileSize } from '@/validators/fileSize';

// Language loader mixin
import languages from '../mixins/languages';
import errors from '../mixins/errors';

import EximyrBreadcrumb from '../components/EximyrBreadcrumb';
import EximyrLoading from '../components/EximyrLoading';

export default {
  components: {
    EximyrBreadcrumb,
    //  EximyrHelp,
    EximyrLoading,
  },

  mixins: [languages, errors],

  data: () => ({
    create: false,

    id: 0,
    restaurant_uuid: 0,

    // All languages are needed so we can have reactivity
    name: { de: null, en: null, es: null, it: null, fr: null, pt: null },
    description: { de: null, en: null, es: null, it: null, fr: null, pt: null },

    active: true,

    image: null,
    imageFile: '',

    loadingTranslation: false,
    translatedName: '', // Prevent multiple clicks
    translatedDescription: '', // Prevent multiple clicks
  }),

  computed: {
    ...mapGetters('security', {
      isAdmin: 'isAdmin',
      isEmployee: 'isEmployee',
    }),

    ...mapGetters('allergens', {
      allergen: 'allergen',
      loading: 'loading',
    }),

    breadcrumbs() {
      const breadcrumbs = [
        {
          text: this.$t('navigation.allergens'),
          disabled: false,
          exact: true,
          to: { name: 'allergens' },
        },
      ];

      if (this.create) {
        breadcrumbs.push({
          text: this.$t('allergens.breadcrumb.create'),
          disabled: true,
          exact: false,
        });
      } else {
        breadcrumbs.push({
          text: this.$t('allergens.breadcrumb.edit', {
            name: this.name[this.$i18n.locale]
              ? this.name[this.$i18n.locale]
              : this.name[this.$i18n.fallbackLocale],
          }),
          disabled: true,
          exact: false,
        });
      }

      return breadcrumbs;
    },

    computedImage() {
      // We cannot use a constant in the src of an image so we
      // must create a computed property
      return this.image ? this.image : IMAGE_NO_ALLERGEN;
    },

    // Validation errors
    rules() {
      return {
        image: [fileRequired(this), fileSize(this, 512)],
        name: [required(this), length(this, 150)],
        description: [length(this, 255)],
        display: [required(this), numeric(this), length(this, 10)],
      };
    },
  },

  created() {
    // Check if we are in edit mode or in create mode
    if (!this.$route.params.allergen) {
      this.create = true;

      return;
    }

    // We must load the allergen now
    this.$store
      .dispatch('allergens/loadAllergen', {
        restaurant: this.$route.params.restaurant,
        allergen: this.$route.params.allergen,
      })
      .then(response => {
        this.id = response.data.id;
        this.restaurant_uuid = response.data.restaurant_uuid;
        this.image = response.data.image_path;

        // We need to copy all data from one to another - using this
        // for we preserve older data
        let locale;
        for (locale in response.data.name) {
          this.name[locale] = response.data.name[locale];
        }
        for (locale in response.data.description) {
          this.description[locale] = response.data.description[locale];
        }

        this.active = response.data.active;
      })
      .catch(error => {
        this.storeError(error);
      });
  },

  methods: {
    cancel() {
      this.$router.push({
        name: 'allergens',
        params: {
          restaurant: this.$route.params.restaurant,
        },
      });
    },

    save() {
      if (!this.$refs.form.validate()) {
        goTo(0);
        return;
      }

      let allergen = {
        restaurant_uuid: this.$route.params.restaurant,
        image: this.imageFile,
        name: this.name,
        description: this.description,
        active: this.active,
      };

      let action = '';
      if (this.create) {
        // Create new allergen
        action = 'allergens/create';
      } else {
        // Update the allergen - set the allergen's id
        allergen.id = this.allergen.id;
        action = 'allergens/update';
      }

      // Update / create the allergen
      this.$store
        .dispatch(action, allergen)
        .then(() => {
          // Clear all errors
          this.clearErrors();

          // Once created get back to the list
          this.$router.push({
            name: 'allergens',
            params: {
              restaurant: this.$route.params.restaurant,
            },
          });
        })
        .catch(error => {
          this.storeError(error);
          goTo(0);
        });
    },

    onFilePicked(file) {
      if (file) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener('load', () => {
          this.image = fr.result;
          this.imageFile = file; // this is an image file that can be sent to server...
        });
      } else {
        this.imageFile = '';

        // We must show the latest image, if its a create action
        // now then use the default image
        this.image = this.create ? null : this.allergen.image_path;
      }
    },

    googleTranslate() {
      this.loadingTranslation = true;
      this.clearErrors();
      // Get spanish translations
      // console.log(this.name[this.$i18n.locale]);
      // console.log(this.description[this.$i18n.locale]);

      const payload = {
        input: [
          this.name[this.$i18n.locale],
          this.description[this.$i18n.locale],
        ],
        source: this.$i18n.locale,
        // Target all locales but the default one 'es'
        target: Object.keys(this.name).filter(
          locale => locale !== this.$i18n.locale
        ),
      };

      this.$store
        .dispatch('translation/translate', payload)
        .then(response => {
          // Get all locales from the name
          Object.keys(this.name)
            // Remove the source locale (es)
            .filter(locale => locale !== this.$i18n.locale)
            // Save all translations for the name and description
            .forEach(code => {
              this.name[code] = response.data[code]['0'].text;
              if (response.data[code]['1']) {
                this.description[code] = response.data[code]['1'].text;
              }
            });

          // Prevent multiple clicks for the same translations
          this.translatedName = this.name[this.$i18n.locale];
          this.translatedDescription = this.description[this.$i18n.locale];
        })
        .catch(error => {
          this.storeError(error);
          this.error(error.response);
        })
        .finally(() => {
          this.loadingTranslation = false;
        });
    },
  },
};
</script>
